import React from "react"
// import Rickshaw from "rickshaw";
import "rickshaw/rickshaw.css"

function find_definition (target_graphite, options) {
    var matching_i;
    for (var cfg_i = 0; cfg_i < options.targets.length && matching_i === undefined; cfg_i++) {
        // string match (no globbing)
        if(options.targets[cfg_i].target === target_graphite.target) {
            matching_i = cfg_i;
        }
    }
    if (matching_i === undefined) {
        console.error("internal error: could not figure out which target_option target_graphite '" +
            target_graphite.target + "' comes from");
        return [];
    }
    return options.targets[matching_i];
}


class GraphiteChart extends React.Component {
  constructor() {
    super();
    this.state = {window: "1day", graphite_data: []}
    this.chart = React.createRef();
  }
  componentDidMount() {
    this.updateGraph()
  }
  componentDidUpdate() {
    if(this.state.window != this.lastWindow) {
      this.updateGraph()
      this.lastWindow = this.state.window;
    }
  }
  drawRick(resp_graphite, options) {
    let Rickshaw = window.Rickshaw;
    var all_targets = [];
    if(resp_graphite.length === 0 ) {
        console.warn("no data in graphite response");
    }
    for (var res_i = 0; res_i < resp_graphite.length; res_i++) {
        var target = find_definition(resp_graphite[res_i], options);
        target.data = [];
        for (var i in resp_graphite[res_i].datapoints) {
            target.data[i] = { x: resp_graphite[res_i].datapoints[i][1], y: resp_graphite[res_i].datapoints[i][0] || 0 };
        }
        all_targets.push(target);
    }
    options['element'] = this.chart.current;
    options['series'] = all_targets
    if(!this.graph) {
      this.graph = new Rickshaw.Graph(options);
    } else {
      this.graph.configure(options)
      // this.graph.setSeries(options['series'])
      options['series'].active = this.graph.series.active
      this.graph.series = options['series']
    }
    if(options['hover_details']) {
        new Rickshaw.Graph.HoverDetail( {
            graph: this.graph
        } );
    }
    var setRickshawOptions = function (options, graph) {
        if ('state' in options && options['state'] === 'stacked') {
            graph.setRenderer('stack');
            graph.offset = 'zero';
        }
        else { // 'state' is lines
            graph.setRenderer('line');
            graph.offset = 'zero';
        }
    }
    setRickshawOptions(options, this.graph);
    this.graph.render();
  }
  updateGraph() {
    fetch(`/api/org/${this.props.orgid}/metrics/${this.props.apikey}?window=${this.state.window}`).then((r) => { return r.json()}).then((data) => {
      if(!data.graphiteData) {
        this.setState({"graphite_error": "No Metrics Available", "graphite_data": []})
      } else {
        let options = {
            height: '300',
            width: this.chart.current.getBoundingClientRect().width,
            title: 'requests by method',
            vtitle: 'request volume',
            drawNullAsZero: true,
            // legend: 'legend_rickshaw',
            legend_highlight: true,
            state: 'lines',
            // line_stack_toggle: 'line_stack_form_rickshaw',
            hover_details: true,
            targets: data.targets,
        };
        this.setState({"graphite_error": "", "graphite_data": data.graphiteData})
        this.drawRick(data.graphiteData, options);
      }
    })
  }
  setDate(e) {
    this.setState({"window": e.target.value});
  }
  render() {
    let totals = [];
    let totalElements = [];
    for(let item of this.state.graphite_data) {
      let nameParts = item.tags.name.split(".");
      let name = nameParts[nameParts.length - 2];
      let total = 0;
      for(let datapoint of item.datapoints) {
        total += Math.ceil(datapoint[0]);
      }
      totals.push(<li key={name}>{name}: {total}</li>)

    }
    if(!this.state.graphite_error) {
      return (
        <div>
        <select defaultValue="1day" onChange={this.setDate.bind(this)}>
        <option value="1day">1 day</option>
        <option value="7day">7 days</option>
        <option value="30day">30 days</option>
        </select>
        <br/><br/>
        <div style={{"backgroundColor": "white", "color": "black"}} className="chart_container rickshaw" id="chart_container_rickshaw">
        <div className="graphite_error">{this.state.graphite_error}</div>
        <div className="chart_y_axis" id="y_axis_rickshaw"></div>
        <div className="chart" ref={this.chart} id="chart_rickshaw"></div>
        <div className="legend" id="legend_rickshaw"></div>
        <form className="toggler" id="line_stack_form_rickshaw"></form>
        </div>
        <div className="totals">
        <h2>Total By Call</h2>
        <ul>
        {totals}
        </ul>
        </div>
        <div>
        Key usage statistics are approximate, and may not exactly match biilling records.
        </div>
        </div>
      )
    } else {
      return <div>{this.state.graphite_error}</div>
    }
  }
}

export default GraphiteChart;
