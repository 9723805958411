import React from "react"
import { Line } from 'react-chartjs-2';



const options = {
  scales: {
    x: {
      ticks: {
        callback: function(value, index, values) {
          // console.log(value, index, values[index])
          if(index % 5 == 0) {
            return this.getLabelForValue(value);
          }
          return ""
        }
      }
    }
  }
};


class TimestreamChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      window: '1d',
      lastWindow: '1d',
    }
  }
  setDate(e) {
    this.setState({"window": e.target.value});
  }
  componentDidUpdate(prevProps) {
    if((!prevProps.expand && this.props.expand) || (this.state.window != this.lastWindow)) {
      this.setState({data: {}, totals: []})
      fetch(`/api/org/${this.props.orgid}/metricsv2/${this.props.apikey}?window=${this.state.window}`).then((r) => { return r.json()}).then((r) => {
        if(r.ok) {
          let data = r.data;
          let totals = []
          for(let ds of data.datasets) {
            totals.push(<li key={ds.label}>{ds.label}: {ds.data.reduce((a, b) => { return a + b })}</li>)
          }
          this.setState({data: data, totals: totals})
        } else {
          this.setState({error: r.error})
        }
      });
    }
    if(this.state.window != this.lastWindow) {
      this.lastWindow = this.state.window;
    }
  }
  render() {
    if (this.state.error) {
      return <p>{this.state.error}</p>
    }
    return(
    <>
      <select defaultValue="1d" onChange={this.setDate.bind(this)}>
        <option value="1d">1 day</option>
        <option value="7d">7 days</option>
        <option value="30d">30 days</option>
      </select>
      <Line data={this.state.data} options={options} />
      <div className="totals">
      <h2>Total By Call</h2>
      <ul>
      {this.state.totals}
      </ul>
      </div>
    </>)
  }
}

export default TimestreamChart;
