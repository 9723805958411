/* eslint-disable */
import React from "react"
import DashNavbar from "./DashNavbar"
import "./layout.css"

const DashLayout = ({ children }) => {
  return (
    <main>
      <DashNavbar />
      {children}
    </main>
  )
}

export default DashLayout
