/* eslint-disable */
import React, { useRef } from "react"
import Title from "../../Title"
import styles from "../../../css/dashboard.module.css"
import Utils from "../../../utils"
import GraphiteChart from "./GraphiteChart"
import TimestreamChart from "./TimestreamChart"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { navigate } from "gatsby"
import {entropyToMnemonic} from "bip39"


class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      username: "",
      email: "",
      isweb3: "",
      orgs: [],
      active_org: {name: "loading"},
      display_mnemonic: false,
      network: "eth",
      confirm_downgrade: false,
      changing_plan: false,
      expanded_keys: {},
    };
  }
  handleChange(e) {
    let state = {};
    state[e.target.name] = e.target.value;
    this.setState(state);
  }
  handlePersistedChange(e) {
    let state = {};
    state[e.target.name] = e.target.value;
    this.setState(state);
    if(typeof window !== "undefined") {
      window.localStorage.setItem(e.target.name, JSON.stringify(state[e.target.name]));
    }
  }
  handleToggle(e) {
    let state = {};
    state[e.target.name] = !this.state[e.target.name];
    this.setState(state)
    if(typeof window !== "undefined") {
      window.localStorage.setItem(e.target.name, JSON.stringify(state[e.target.name]));
    }
  }
  componentDidMount() {
    fetch("/api/account").then((response) => {
      if(!response.ok) {
        navigate("/login/");
        throw "Not logged in"
      } else {
        return response.json()
      }
    }).then((result) => {
      let state = {username: result.username, orgs: result.orgs, email: result.email, isweb3: result.isweb3};
      this.setState(state);
      if(result.orgs.length > 0) {
        return this.setActiveOrg(result.orgs[0]);
      } else {
        if(typeof(dataLayer) == 'object') {
          dataLayer.push({'event': 'web3-signup-start'});
        }
        navigate("/org-setup/");
      }
    }).catch((err) => {
      console.log(err);
    })
    if(typeof window !== "undefined") {
      this.setState({
        display_mnemonic: JSON.parse(window.localStorage.getItem("display_mnemonic")),
        network: JSON.parse(window.localStorage.getItem("network")) || "eth",
      });
    }
  }
  setActiveOrg(org) {
    let both = [fetch(`/api/org/${org.id}`).then((r) => r.json()).then((org_info) => {
      let state = {active_org: org};
      state["payment"] = org_info.payment.brand || "Not On File";
      state["plan"] = org_info.plan;
      state["target_plan"] = org_info.plan;
      state["usage"] = org_info.usage;
      state["days_left_in_cycle"] = parseInt((new Date(org_info.cycle_end) - new Date()) / 1000 / 24 / 60/ 60);
      state["keys"] = org_info.tokens;
      state["referral_code"] = org_info.referral_code;
      console.log(state)
      this.setState(state)
    }),
    fetch(`/api/org/${org.id}/billing`).then((r) => r.json()).then((billing_info) => {
      let state = {};
      state["metered_cost"] = billing_info.cost/100;
      state["next_tier"] = billing_info.next_tier;
      state["credits"] = billing_info.available_credits;
      if(billing_info.rate !== 0) {
        state["rate"] = `${billing_info.rate/100} / 100k requests`
      } else {
        state["rate"] = "Free";
      }
      this.setState(state)
    })];
    Promise.all(both).then(() => {
      console.log(this.state)
      let hasDisplayed = false;
      if(typeof window !== 'undefined' && window) {
        hasDisplayed = !!window.localStorage.getItem("confirmedCryptoCredits");
      }
      if(!hasDisplayed && ((this.state.plan == "CRYPTO" && this.state.credits < 100000) || this.state.plan == "BUIDL" && this.state.credits + 3000000 - this.state.usage < 100000)) {
        this.setState({"directToCredits": true})
      }
    })
  }
  updateKeyNameLocal(e) {
    let keys = this.state.keys;
    keys[e.target.dataset.key].name = e.target.value;
    this.setState({keys: keys});
  }
  updateKeyActiveLocal(value) {
    return (e) => {
      let keys = this.state.keys;
      keys[e.target.dataset.key].active = value;
      this.setState({keys: keys});
    }
  }
  updateKey(e) {
    e.preventDefault();
    let key = e.target.dataset.key
    let key_data = this.state.keys[key];
    fetch(`/api/org/${this.state.active_org.id}/tok/${key}`, {
      method: "PUT",
      body: JSON.stringify({"active": key_data.active, "name": key_data.name}),
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": Utils.getCookie('csrftoken'),
      }
    }).then((r) => {
      if(!r.ok) {
        key_data.notice = "An error occurred"
      } else {
        key_data.notice = "Updated!"
      }
      let keys = this.state.keys;
      keys[key] = key_data
      this.setState({keys: keys})
    })
  }

  updateEmail(e) {
    e.preventDefault();
    this.setState({email_update_status: ""})
    fetch("/api/account/email", {
      method: "POST",
      body: JSON.stringify({"new_email": this.state.new_email}),
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": Utils.getCookie('csrftoken'),
      }
    }).then((r) => {
      if(!r.ok) {
        this.setState({email_update_status: "Error updating email address"});
      } else {
        this.setState({username: this.state.new_email, email_update_status: "Updated!", new_email: ""})
      }
    })
  }
  updatePassword(e) {
    e.preventDefault();
    this.setState({password_update_status: ""})
    if(this.state.new_password != this.state.repeat_new_password) {
      this.setState({password_update_status: "Password Confirmation Does Not Match"})
      return
    }
    fetch("/api/account/password", {
      method: "POST",
      body: JSON.stringify({"old_password": this.state.old_password, "new_password": this.state.new_password}),
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": Utils.getCookie('csrftoken'),
      }
    }).then((r) => r.json()).then((r) => {
      if(!r.ok) {
        if (r.error == "auth-failed") {
          this.setState({password_update_status: "Authentication Failed"});
        } else {
          this.setState({password_update_status: "An Unknown Error Has Occurred"});
        }
      } else {
        this.setState({password_update_status: "Updated!"})
        window.location.reload()
      }
    })
  }
  addKey(e) {
    e.preventDefault();
    fetch(`/api/org/${this.state.active_org.id}/tok`, {
      method: "POST",
      body: JSON.stringify({"active": true, "name": this.state.new_key_name}),
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": Utils.getCookie('csrftoken'),
      }
    }).then((r) => r.json()).then((r) => {
      let keys = this.state.keys;
      keys[r.token_id] = {"name": this.state.new_key_name, "active": true}
      this.setState({keys: keys, new_key_name: ""})
    })
  }
  // updatePlan(e) {
  //   e.preventDefault();
  //   if(this.state.target_plan == this.state.plan) {
  //     this.setState({plan_notice: "You are already on this plan"})
  //     return
  //   }
  //   if(this.state.payment == "Not On File" && this.state.target_plan == "SCAEL") {
  //     this.setState({plan_notice: "You must set up a payment method before switching to SCAEL"})
  //     return
  //   }
  //   fetch(`/api/org/${this.state.active_org.id}/plan`, {
  //     method: "POST",
  //     body: JSON.stringify({"plan": this.state.target_plan}),
  //     headers: {
  //       "Content-Type": "application/json",
  //       "X-CSRFToken": Utils.getCookie('csrftoken'),
  //     }
  //   }).then((r) => r.json()).then((r) => {
  //     if(r.ok) {
  //       this.setState({plan_notice: "Plan Updated", plan: this.state.target_plan})
  //     } else if(r.error == "no-billing") {
  //       this.setState({plan_notice: "You must set up a payment method before switching to SCAEL"})
  //     } else if (r.error == "same-plan")  {
  //       this.setState({plan_notice: "You are already on this plan"})
  //     } else {
  //       this.setState({plan_notice: "An unknown error occurred"})
  //     }
  //   })
  // }
  setPlan(target_plan) {
    this.setState({changing_plan: true})
    fetch(`/api/org/${this.state.active_org.id}/plan`, {
      method: "POST",
      body: JSON.stringify({"plan": target_plan}),
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": Utils.getCookie('csrftoken'),
      }
    }).then((r) => r.json()).then((r) => {
      if(r.ok) {
        this.setState({plan_notice: "Plan Updated", plan: target_plan})
      } else if(r.error == "no-billing") {
        this.setState({plan_notice: "You must set up a payment method before switching to SCAEL"})
      } else if (r.error == "same-plan")  {
        this.setState({plan_notice: "You are already on this plan"})
      } else {
        this.setState({plan_notice: "An unknown error occurred"})
      }
    })
  }
  initDowngrade() {
    this.setState({"confirm_downgrade": true});
  }
  render() {
    const handleCopy = (content) => {
      return (e) => {
        e.preventDefault();
        var textField = document.createElement('textarea')
        textField.innerText = content
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
      }
    }
    let cardInfo;
    if(this.state.payment != "Not On File") {
      cardInfo = <AniLink fade to={`/paymethod/?org=${this.state.active_org.id}`} className={styles.buyCreditsLink}>Update Credit Card on File</AniLink>
    } else {
      cardInfo = <AniLink fade to={`/paymethod/?org=${this.state.active_org.id}`} className={styles.buyCreditsLink}>Add a Credit Card</AniLink>
    }
    const displayKey = (key) => {
      if(this.state.display_mnemonic) {
        return entropyToMnemonic(key).split(" ").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join("");
      }
      return key
    }
    let planLink;
    let upgradeLink;
    if(this.state.plan != "SCAEL") {
      planLink = <AniLink fade to={"/paymethod/?org=" + this.state.active_org.id + "&scael=1"} className={styles.upgradeLabelTop}>Upgrade to SCAEL</AniLink>
      upgradeLink = planLink;
    } else {
      if(!this.state.confirm_downgrade) {
        planLink = (
          <div className={styles.buttonWrapper}>
          <AniLink fade to={"/paymethod/?org=" + this.state.active_org.id} className={styles.upgradeLabel}>Update Payment Information</AniLink>
          <button className={styles.submit} onClick={this.initDowngrade.bind(this)}>
            Downgrade to BUIDL
          </button>
          </div>
        )
      } else {
        // TODO: Greg review language below DONE
        planLink = (
          <div className={styles.buttonWrapper}>
            <AniLink fade to={"/paymethod/?org=" + this.state.active_org.id} className={styles.upgradeLabel}>Update Payment Information</AniLink>
            <button disabled={this.state.changing_plan} className={styles.submit} onClick={() => this.setPlan("BUIDL")}>
              Confirm
            </button>
            <p>
              Important: If you downgrade your plan, your endpoint will be restricted to 3,000,000 requests per month.
            </p>
          </div>
        )
      }
    }
    let orgs = [];
    let keys = [];
    for(let org of this.state.orgs) {
      orgs.push(<li key={org.id} onClick={() => this.setActiveOrg(org) }>{org.name}</li>)
    }
    let first = true;
    let clear = () => {
      this.setState({directToCredits: false});
      if(typeof window !== 'undefined' && window) {
        window.localStorage.setItem("confirmedCryptoCredits", "true");
      }
    }
    let dontclear = (e) => {
      e.stopPropagation();
    }
    let creditsPopup = [];
    if(this.state.directToCredits) {
      console.log("Should direct to credits");
      let remaining = 0;
      if(this.state.plan == "CRYPTO") {
        remaining = this.state.credits;
      } else if (this.state.plan == "BUIDL") {
        remaining = this.state.credits + 3000000 - this.state.usage;
      }
      let currentState = "";
      if(remaining == 0) {
        currentState = <p>It looks like you don't have any RPC credits. If you signed up with a promo code, those credits will be applied shortly. You won't be able to make request against Rivet until you take care of this.</p>
      } else {
        currentState = <p>It looks like you're getting low on RPC credits. Top up soon to avoid running out.'</p>
      }
      creditsPopup.push(
        <div key="wrapper" onClick={clear} className={styles.offerWrapper}><div onClick={dontclear} className={styles.offer}>
          <h2>Welcome</h2>
          {currentState}
          <p>You have two options:</p>
          <ul>
            <li><AniLink fade to={`/paymethod/?org=${this.state.active_org.id}&scael=1`} className={styles.upgradeOption}>Sign up for a SCAEL Plan</AniLink>: SCAEL Plans get 3,000,000 requests per month for free, and we'll bill your credit card $1 for each additional block of 100,000 RPC requests.</li>
            <li><AniLink fade to={`/buy-credits/?org=${this.state.active_org.id}`} className={styles.upgradeOption}>Prepay for RPC Credits</AniLink>: Pay up front in ETH or DAI to get requests at $1 per 100,000 requests. Discounts are available at high volumes.</li>
          </ul>
        </div></div>
      )
    }
    for(let key in this.state.keys) {
      let data = this.state.keys[key];
      let url_resources = [];
        url_resources.push(
          <div key={key + "rpc"} className={styles.buttonWrapper}>
            <input className={styles.formControl} type="text" name="key_name" value={this.state.key_name} placeholder={""} onChange={this.handleChange.bind(this)} value={`https://${displayKey(key)}.${this.state.network}.rpc.rivet.cloud/`}></input>
            <button className={styles.submit} onClick={handleCopy(`https://${displayKey(key)}.${this.state.network}.rpc.rivet.cloud/`)}>
              Copy RPC URL
            </button>
          </div>
        )
        url_resources.push(
          <div key={key + "ws"} className={styles.buttonWrapper}>
            <input className={styles.formControl} type="text" name="key_name" value={this.state.key_name} placeholder={""} onChange={this.handleChange.bind(this)} value={`wss://${displayKey(key)}.${this.state.network}.ws.rivet.cloud/`}></input>
            <button className={styles.submit} onClick={handleCopy(`wss://${displayKey(key)}.${this.state.network}.ws.rivet.cloud/`)}>
              Copy Websockets URL
            </button>
          </div>
        )
        // url_resources.push(
        //   <div className={styles.buttonWrapper}>
        //     <input className={styles.formControl} type="text" name="key_name" value={this.state.key_name} placeholder={""} onChange={this.handleChange.bind(this)} value={`https://${displayKey(key)}.${this.state.network}.graphql.rivet.cloud/`}></input>
        //     <button className={styles.submit} onClick={handleCopy(`https://${displayKey(key)}.${this.state.network}.graphql.rivet.cloud/`)}>
        //       Copy GraphQL URL
        //     </button>
        //   </div>
        // );
      if (this.state.network == "eth2") {
        url_resources = (
          <div key={key} className={styles.buttonWrapper}>
            <input className={styles.formControl} type="text" name="key_name" value={this.state.key_name} placeholder={""} onChange={this.handleChange.bind(this)} value={`https://${displayKey(key)}.${this.state.network}.rest.rivet.cloud/`}></input>
            <button className={styles.submit} onClick={handleCopy(`https://${displayKey(key)}.${this.state.network}.rest.rivet.cloud/`)}>
              Copy REST URL
            </button>
          </div>
        );
      }
      let expand = () => {
        let updated_keys = this.state.expanded_keys;
        updated_keys[key] = true;
        this.setState({expanded_keys: updated_keys })
      }
      keys.push(<li key={key}>
      <details open={first} className = {styles.popins}>
        <summary>{data.name}: {displayKey(key)}</summary>
        <article className={styles.keyBoxBox}>
        <form className={styles.form} data-key={key} onSubmit={this.updateKey.bind(this)}>
          <div className={styles.dashboardText}>
            <div className={styles.floatLabel}>Custom Name</div><input type="text" placeholder="Custom Key Name" data-key={key} onChange={this.updateKeyNameLocal.bind(this)} value={data.name} />
          </div>
          <div className={styles.radioWrapper} onChange={this.updateKeyActiveLocal.bind(this)}>
            <input type="radio" name={"active" + key} className={styles.formControl} data-key={key} checked={data.active} value="active" onChange={this.updateKeyActiveLocal.bind(this)} id={"active" + key}/>
            <label data-key={key} onClick={this.updateKeyActiveLocal(true)} htmlFor="keyActive">Key Active</label>
            <input type="radio" name={"active" + key} className={styles.formControl} data-key={key} checked={!data.active} value="inactive" onChange={this.updateKeyActiveLocal.bind(this)} id={"inactive" + key} />
            <label data-key={key} onClick={this.updateKeyActiveLocal(false)} htmlFor="keyInactive">Key Inactive</label>
          </div>
          <div className={styles.buttonWrapper}>
            <button className={styles.submit} onClick={handleCopy(displayKey(key))}>
              Copy Key
            </button>
          </div>
          <div className={styles.urlButtons}>
           {url_resources}
          </div>
          <div  className={styles.buttonWrapper}>
            <input type="submit" name="submit" className={styles.submit} value="Apply Changes" />
          </div>
          <div>
            {data.notice}
          </div>
        </form>
        <details className={styles.keyusage}>
          <summary onClick={expand}>Key usage</summary>
          <article><TimestreamChart orgid={this.state.active_org.id} apikey={key} expand={this.state.expanded_keys[key]} /></article>
        </details>
        </article>
      </details>
      </li>);
      first = false;
    }
    return (
      <section className={styles.dashboard}>
        <span className={styles.modalHeader}><Title title="Rivet Dashboard" />
        <select name="network" defaultValue={this.state.network} onChange={this.handlePersistedChange.bind(this)} className={styles.selectElement}>
          <option value="eth">Ethereum Mainnet</option>
          <option value="eth2">Ethereum 2.0 Beacon Chain</option>
          <option value="etc">Ethereum Classic</option>
          <option value="goerli">Goerli Testnet</option>
          <option value="rinkeby">Rinkeby Testnet</option>
          <option value="ropsten">Ropsten Testnet</option>
        </select></span>
        <div className={styles.center}>
          <section className={styles.row1}>
          <article className={styles.keyBox}>
            <h4>My Keys<input type="checkbox" id="display_mnemonic" name="display_mnemonic" checked={this.state.display_mnemonic} onChange={this.handleToggle.bind(this)} className={styles.checkboxBox}/><label htmlFor="display_mnemonic" className={styles.checkboxLabel}>Toggle Key Mnemonic</label></h4>
            <ul className={styles.boxContent}>
              {keys}
              <li className = {styles.newControlsBox}>
                <form className={styles.form} onSubmit={this.addKey.bind(this)}>
                  <div className={styles.dashboardText}>
                    <div className={styles.textWrapper}>
                    <label className={styles.textLabel}><span className={styles.deglow}>Create New Key</span></label>
                    <input type="text" name="new_key_name" value={this.state.new_key_name} placeholder="New Key Name" onChange={this.handleChange.bind(this)}></input>
                    </div>
                    <div className={styles.buttonWrapper}><input type="submit" name="submit" disabled={!this.state.new_key_name} className={styles.submit} value="Add Key"/></div>
                  </div>
                </form>
              </li>
            </ul>
          </article>
          </section>
          <section className={styles.row2}>
          <article className={styles.bulletinBox}>
            <h4>My Account</h4>
            <ul className={styles.boxContent}>
              <li><details className = {styles.popins}>
                <summary>Organizations: {this.state.active_org.name}</summary>
                <article className={styles.orgList}>
                  <ul>
                    {orgs}
                  </ul>
                </article>
                </details>
              </li>
              <li>{cardInfo}</li>
              <li>
                <li><AniLink fade to="/update-plan/" className={styles.faqfu}>Update Plan</AniLink></li>
              </li>
              <li><details className = {styles.popins}>
              <summary>Account Email: {this.state.email}</summary>
              <article className={styles.keyBoxBox}>
                <form className={styles.form} onSubmit={this.updateEmail.bind(this)}>
                  <div className={styles.dashboardText}>{this.state.email_update_status}</div>
                  <div className={styles.dashboardText}><input type="email" name="new_email" value={this.state.new_email} placeholder="New Email Address" onChange={this.handleChange.bind(this)}></input></div>
                  <div className={styles.buttonWrapper}><input type="submit" name="submit" className={styles.submit} value="Change Account Email" id="userUpdate"/></div>
                </form>
                </article>
                </details>
              </li>
              <li style={{ display: (this.state.isweb3 ? 'none' : 'block') }}><details className = {styles.popins}>
              <summary>Update Password</summary>
              <div className={styles.passbox}><article className={styles.keyBoxBox}>
                <form className={styles.form} onSubmit={this.updatePassword.bind(this)}>
                  <div className={styles.dashboardText}>{this.state.password_update_status}</div>
                  <div className={styles.dashboardText}><input type="password" name="new_password" placeholder="New Password" onChange={this.handleChange.bind(this)}></input></div>
                  <div className={styles.dashboardText}><input type="password" name="repeat_new_password" placeholder="Repeat New Password" onChange={this.handleChange.bind(this)}></input></div>
                  <div className={styles.dashboardText}><input type="password" name="old_password" placeholder="Old Password" onChange={this.handleChange.bind(this)}></input></div>
                  <div className={styles.buttonWrapper}><input type="submit" name="submit" className={styles.submit} value="Update Password" id="passwordUpdate"/></div>
                </form>
                </article></div>
                </details>
              </li>
              <li style={{"display": !this.state.referral_code ? "none" : "block"}}><details className = {styles.popins}>
              <summary>Referral Link</summary>
              <div className={styles.referbox}><article className={styles.keyBoxBox}>
                <br/>Refer other BUIDLers to Rivet and get a monthly credit based on their usage:
                <br/><br/>
                <input className={styles.formControl} type="text" name="key_name" value={"https://rivet.cloud/signup/?r=" + this.state.referral_code}></input>
                <button className={styles.submit} onClick={handleCopy("https://rivet.cloud/signup/?r=" + this.state.referral_code)}>
                  Copy Endpoint URL
                </button><br/>
                </article></div>
                </details>
              </li>
              <li><AniLink paintDrip to="/faq" hex="#070707" className={styles.instructions}><span className={styles.faqfu}>FAQ</span></AniLink></li>
            </ul>
          </article>
          <article className={styles.accountBox}>
          <h4>Plan Details</h4>
            <ul className={styles.boxContent}>
              <li>Current Plan: {this.state.plan}</li>
              <li>Active Rate: {this.state.rate}</li>
              <li>Usage: {this.state.usage}</li>
              <li style={{display: !this.state.credits ? "none" : "list-item"}}>Credits: {this.state.credits}</li>
              <li><AniLink fade to={`/buy-credits/?org=${this.state.active_org.id}`} className={styles.buyCreditsLink}>Buy Request Credits</AniLink></li>
              <li>Days Left in Cycle: {this.state.days_left_in_cycle}</li>
              <li>Accrued Charges: ${this.state.metered_cost}</li>
              <li><AniLink fade to={`/invoice/?org=${this.state.active_org.id}`}>Invoices</AniLink></li>
              <li><a href="mailto:support@rivet.cloud" target="_blank">Contact Support</a></li>
            </ul>
          </article>
          </section>
        </div>
        {creditsPopup}
      </section>
    )
  }
}

export default Dashboard
